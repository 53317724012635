import { identityRegistration } from './identity-registration';
import { identityExternal } from './identity-external';
import { ApiResponse } from '@http/types';
import { apiClients } from '../..';

export interface IDeleteUserRequest {
  deleteReason?: string;
  deleteReasonText?: string;
}

export const identity = {
  registration: identityRegistration,
  external: identityExternal,
  delete: async (request: IDeleteUserRequest) => {
    const formData = new FormData();
    if (request.deleteReason) {
      formData.append('deleteReason', request.deleteReason);
    }
    if (request.deleteReasonText) {
      formData.append('deleteReasonText', request.deleteReasonText);
    }

    return await apiClients.default.delete<ApiResponse>('v1/identity/reason/term/user', {
      data: request,
    });
  },
};
