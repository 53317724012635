import { apiClients } from '../../index';
import { ApiResponse } from '../../../types';
import { IRubricItem } from '../../../models/rubric-item';

export const rubrics = {
  post: async (request: number[]) => {
    const formData = new FormData();
    request.forEach((item: number) => formData.append('rubricIds', String(item)));
    return await apiClients.default.post<ApiResponse>('rubrics', formData);
  },
  currentUserRubrics: {
    get: async () => {
      return await apiClients.default.get<IRubricItem[]>('rubrics/currentUserRubric');
    },
  },
};
