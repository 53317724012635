import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface IOnboarding {
  home?: boolean;
  challengeList?: boolean;
  skillList?: boolean;
  challenge?: boolean;
  challengeProjectCount?: boolean;
  challengeProjectListButton?: boolean;
}

interface IInteractiveState {
  canShowDeleteNotification: boolean;
  isDownloadAppToolbarVisible: boolean;
  profileScrollPosition?: number;
  showLinkPopup?: boolean | string;
  showComplaintUserPopup?: string;
  showComplaintUserDialog?: string;
  showDeleteProjectDialog?: boolean | number;
  onboarding?: IOnboarding;
  isVideoControlsVisible: boolean;
  showComplaintProjectDialog?: number | null;
  showComplaintProjectPopup?: string;
  showCopyLinkPopup?: boolean | string;
}

const initialState: IInteractiveState = {
  canShowDeleteNotification: false,
  isDownloadAppToolbarVisible: false,
  profileScrollPosition: 0,
  isVideoControlsVisible: false,
};

const slice = createSlice({
  name: 'interactive',
  initialState,
  reducers: {
    setOnboarding: (state, action: PayloadAction<IOnboarding>) => {
      state.onboarding = { ...state.onboarding, ...action.payload };
    },
    setIsDownloadAppToolbarVisible: (state, action: PayloadAction<boolean>) => {
      state.isDownloadAppToolbarVisible = action.payload;
    },
    setProfileScrollPosition: (state, action: PayloadAction<number>) => {
      state.profileScrollPosition = action.payload;
    },
    setShowComplaintUserPopup: (state, action: PayloadAction<string>) => {
      state.showComplaintUserPopup = action.payload;
    },
    setShowComplaintUserDialog: (state, action: PayloadAction<string>) => {
      state.showComplaintUserDialog = action.payload;
    },
    setShowCopyLinkPopup: (state, action: PayloadAction<boolean | string>) => {
      state.showCopyLinkPopup = action.payload;
    },
    setShowComplaintProjectPopup: (state, action: PayloadAction<string>) => {
      state.showComplaintProjectPopup = action.payload;
    },
    setShowComplaintProjectDialog: (state, action: PayloadAction<number | null>) => {
      state.showComplaintProjectDialog = action.payload;
    },
    setShowDeleteProjectDialog: (state, action: PayloadAction<boolean | number>) => {
      state.showDeleteProjectDialog = action.payload;
    },
    setShowVideoControls: (state, action: PayloadAction<boolean>) => {
      state.isVideoControlsVisible = action.payload;
    },
    setBottomSheetHide: (state) => {
      state.showComplaintProjectPopup = undefined;
      state.showCopyLinkPopup = undefined;
      state.showDeleteProjectDialog = undefined;
    },
  },
});

const {
  setIsDownloadAppToolbarVisible,
  setProfileScrollPosition,
  setShowDeleteProjectDialog,
  setOnboarding,
  setShowVideoControls,
  setShowComplaintUserPopup,
  setShowComplaintUserDialog,
  setShowCopyLinkPopup,
  setShowComplaintProjectPopup,
  setShowComplaintProjectDialog,
  setBottomSheetHide,
} = slice.actions;

const interactive = {
  setBottomSheetHide,
  setIsDownloadAppToolbarVisible,
  setOnboarding,
  setProfileScrollPosition,
  setShowDeleteProjectDialog,
  setShowComplaintUserPopup,
  setShowComplaintUserDialog,
  setShowComplaintProjectPopup,
  setShowComplaintProjectDialog,
  setShowCopyLinkPopup,
  setShowVideoControls,
  selectCanShowDeleteNotification: (state: RootState) =>
    state.interactive.canShowDeleteNotification,
  selectIsDownloadAppToolbarVisible: (state: RootState) =>
    state.interactive.isDownloadAppToolbarVisible,
  selectOnboarding: (state: RootState) => state.interactive.onboarding,
  selectProfileScrollPosition: (state: RootState) => state.interactive.profileScrollPosition,
  selectShowDeleteProjectDialog: (state: RootState) => state.interactive.showDeleteProjectDialog,
  selectShowComplaintUserDialog: (state: RootState) => state.interactive.showComplaintUserDialog,
  selectShowComplaintUserPopup: (state: RootState) => state.interactive.showComplaintUserPopup,
  selectShowComplaintProjectPopup: (state: RootState) =>
    state.interactive.showComplaintProjectPopup,
  selectShowComplaintProjectDialog: (state: RootState) =>
    state.interactive.showComplaintProjectDialog,
  selectShowCopyLinkPopup: (state: RootState) => state.interactive.showCopyLinkPopup,
  selectIsVideoControlsVisible: (state: RootState) => state.interactive.isVideoControlsVisible,
};

export const interactiveReducer = slice.reducer;

export default interactive;
