import { ApiResponse } from '../../../types';
import { apiClients } from '../..';

export interface IQuizAnswer {
  order: number;
  answerText?: string;
  selectedOptions?: number[];
}

export interface IQuizInfoReward {
  correctAnswersCondition: string;
  attemptNumberText: string;
  attemptNumber: number;
  points: number;
  experience: number;
}

export interface IQuizInfo {
  id: number;
  name: string;
  type: 'Open' | 'Closed';
  questionCount: number;
  duration: number;
  passingThresholdPercent: number;
  attemptNumber: number;
  isPassed: boolean;
  rewards: IQuizInfoReward[];
  challengeId: number;
}

export interface IQuizQuestion {
  id: number;
  quizId: number;
  text: string;
  answers: string[];
  correctAnswer: number;
}

export interface IQuizInfoResponse extends ApiResponse, IQuizInfo {}
export interface IQuizQuestionsResponse extends ApiResponse, IQuizQuestion {}

export interface IQuizSubmitRequest {
  isFinal: boolean;
  answers: IQuizAnswer[];
}

export const quiz = {
  info: {
    get: async (challengeId: number) =>
      await apiClients.default.get<IQuizInfoResponse>('v1/quiz/info', {
        params: { challengeId },
      }),
  },
  questions: {
    get: async (quizId: number) =>
      await apiClients.default.get<IQuizQuestionsResponse>(`v1/quiz/${quizId}/questions`),
  },
  start: {
    post: async (quizId: number) =>
      await apiClients.default.post<ApiResponse>(`v1/quiz/${quizId}/start`),
  },
  submit: {
    post: async ({ quizId, request }: { quizId: number; request: IQuizSubmitRequest }) =>
      await apiClients.default.post<ApiResponse>(`v1/quiz/${quizId}/submit`, {
        params: request,
      }),
  },
};
