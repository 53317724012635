import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IOrganizationModuleItem } from '@api/v1/organization/organization';
import { RootState } from '../store';
import { normalizeOrganizationOptions } from '@utils/normalize-organization-options';
import { TLoadingStatus } from '../../types/loading-status';
import { ELoadingStatus } from '@http/enums';
import { v1 } from '@api/v1';

interface OptionsState {
  items: Record<string, IOrganizationModuleItem>;
  status: TLoadingStatus;
  error: string | null;
}

const initialState: OptionsState = {
  items: {},
  status: ELoadingStatus.Idle,
  error: null,
};

export const fetchOptions = createAsyncThunk('options/fetchOptions', async () => {
  const performRequest = async (retryCount = 0): Promise<IOrganizationModuleItem[]> => {
    try {
      const data = await v1.organization.moduleOption.get();

      if (data.errorCode !== undefined || data.errorMsg !== undefined) {
        throw new Error(data.errorMsg);
      }

      return data.items;
    } catch (error) {
      if (
        //todo нужно сделать проверку на ошибку 404
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 404 &&
        retryCount < 2
      ) {
        return performRequest(retryCount + 1); // Повторить запрос, увеличив счетчик попыток
      } else {
        throw error; // Выбросить исключение, если условия не выполнены или достигнут лимит попыток
      }
    }
  };

  return performRequest(); // Инициировать выполнение запроса
});

const slice = createSlice({
  name: 'data',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOptions.pending, (state) => {
        state.status = ELoadingStatus.Loading;
        state.error = null;
      })
      .addCase(fetchOptions.fulfilled, (state, action) => {
        state.status = ELoadingStatus.Succeeded;
        state.items = normalizeOrganizationOptions(action.payload);
      })
      .addCase(fetchOptions.rejected, (state, action) => {
        state.status = ELoadingStatus.Failed;
        state.error = action.payload as string;
      });
  },
});

const options = {
  selectItems: (state: RootState) => state.options.items,
  selectError: (state: RootState) => state.options.error,
  selectStatus: (state: RootState) => state.options.status,
};

export const optionsReducer = slice.reducer;
export default options;
