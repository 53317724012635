import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store';
import { uniqBy } from 'lodash';
import { IProjectItem } from '../../http/models/project-item';
import { batch } from 'react-redux';
import { v1 } from '@api/v1';

interface IUserProjectListState {
  error?: string;
  loading: boolean;
  items: IProjectItem[];
  total: number;
  page: number;
}

const initialState: { [userId: string]: IUserProjectListState } = {};

const createState = (): IUserProjectListState => ({
  loading: true,
  items: [],
  total: 0,
  page: 1,
});

const slice = createSlice({
  name: 'userProjectList',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<{ userId: string; loading: boolean }>) => {
      state[action.payload.userId] = state[action.payload.userId] ?? createState();
      state[action.payload.userId].loading = action.payload.loading;
    },
    setError: (state, action: PayloadAction<{ userId: string; error?: string }>) => {
      state[action.payload.userId] = state[action.payload.userId] ?? createState();
      state[action.payload.userId].error = action.payload.error;
    },
    setItems: (state, action: PayloadAction<{ userId: string; items: IProjectItem[] }>) => {
      state[action.payload.userId] = state[action.payload.userId] ?? createState();
      state[action.payload.userId].items = action.payload.items;
    },
    addItems: (state, action: PayloadAction<{ userId: string; items: IProjectItem[] }>) => {
      state[action.payload.userId].items = uniqBy(
        [...state[action.payload.userId].items, ...action.payload.items],
        'id',
      );
    },
    setTotal: (state, action: PayloadAction<{ userId: string; total: number }>) => {
      state[action.payload.userId] = state[action.payload.userId] ?? createState();
      state[action.payload.userId].total = action.payload.total;
    },
    setPage: (state, action: PayloadAction<{ userId: string; page: number }>) => {
      state[action.payload.userId] = state[action.payload.userId] ?? createState();
      state[action.payload.userId].page = action.payload.page;
    },
    reset: () => initialState,
  },
});

const { setLoading, setError, setTotal, addItems, setPage, setItems, reset } = slice.actions;

const userProjectList = {
  addItems,
  setPage,
  setItems,
  reset,
  setLoading: (userId: string, loading: boolean) => setLoading({ userId, loading }),
  setError: (userId: string, error?: string) => setError({ userId, error }),
  selectLoading: (userId: string) => (state: RootState) =>
    state.userProjectList[userId]?.loading ?? false,
  selectError: (userId: string) => (state: RootState) => state.userProjectList[userId]?.error,
  selectItems: (userId: string) => (state: RootState) => state.userProjectList[userId]?.items ?? [],
  selectTotal: (userId: string) => (state: RootState) => state.userProjectList[userId]?.total,
  loadItems:
    ({ userId, loadMore }: { userId: string; loadMore?: boolean }): AppThunk =>
    async (dispatch, getState) => {
      const state = getState();
      batch(() => {
        dispatch(setError({ userId, error: undefined }));
      });

      try {
        const response = await v1.project.get({
          author: { id: [userId] },
          medias: { fetch: true },
          page: loadMore ? state.userProjectList[userId].page + 1 : 1,
          take: 20,
        });

        if (response.errorCode) {
          if (response.errorCode === 500) {
            dispatch(
              setError({
                userId,
                error:
                  'Не получилось отправить запрос на сервер. Возможно он слишком большой/тяжелый',
              }),
            );
          }
          dispatch(setError({ userId, error: response.errorMsg }));
          return;
        }

        batch(() => {
          if (loadMore) {
            dispatch(addItems({ userId, items: response.items ?? [] }));
            dispatch(setPage({ userId, page: state.userProjectList[userId].page + 1 }));
          } else {
            dispatch(setPage({ userId, page: 1 }));
            dispatch(setItems({ userId, items: response.items ?? [] }));
          }
          dispatch(setTotal({ userId, total: response.total ?? 0 }));
        });
      } finally {
        dispatch(setLoading({ userId, loading: false }));
      }
    },
};

export const userProjectListReducer = slice.reducer;
export default userProjectList;
