import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store';
import { SkillTypeListItem } from '@api/v1/skill/skill';
import { isEmpty, uniqBy } from 'lodash';
import { IRubricItem } from '@model/rubric-item';
import { v1 } from '@api/v1';

interface ISkillListState {
  loading: boolean;
  items: SkillTypeListItem[];
  scrollPosition: number;
  error?: string;
  total: number;
  page: number;
  take: number;
}

const initialState: ISkillListState = {
  loading: true,
  items: [],
  total: 0,
  scrollPosition: 0,
  page: 1,
  take: 10,
};

const slice = createSlice({
  name: 'skillList',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setItems: (state, action: PayloadAction<SkillTypeListItem[]>) => {
      state.items = action.payload;
    },
    addItems: (state, action: PayloadAction<SkillTypeListItem[]>) => {
      state.items = uniqBy([...state.items, ...action.payload], 'id');
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
    setScrollPosition: (state, action: PayloadAction<number>) => {
      state.scrollPosition = action.payload;
    },
  },
});

const { setLoading, setItems, setError, setTotal, setPage, addItems, setScrollPosition } =
  slice.actions;

const skillList = {
  setPage,
  addItems,
  setLoading,
  setItems,
  setError,
  setScrollPosition,
  selectLoading: (state: RootState) => state.skillList.loading,
  selectItems: (state: RootState) => state.skillList.items,
  selectError: (state: RootState) => state.skillList.error,
  selectTotal: (state: RootState) => state.skillList.total,
  selectScrollPosition: (state: RootState) => state.skillList.scrollPosition,
  loadData:
    (rubricList?: IRubricItem[], loadMore?: boolean): AppThunk =>
    async (dispatch, getState) => {
      const state = getState();

      try {
        dispatch(setError());
        dispatch(setLoading(true));

        const rubricId = isEmpty(rubricList) ? [] : rubricList?.map((i) => i.id);

        const response = await v1.skill.get({
          rubricId,
          page: loadMore ? state.skillList.page + 1 : state.skillList.page,
          take: state.skillList.take,
        });

        if (response.errorCode) {
          dispatch(setError(response.errorMsg));
          console.log(`errorCode: ${response.errorCode}; errorMsg: ${response.errorMsg}`);
          return;
        }

        if (loadMore) {
          dispatch(setPage(state.skillList.page + 1));
        }

        dispatch(setTotal(response.total));
        dispatch(addItems(response.items));
      } finally {
        dispatch(setLoading(false));
      }
    },
};

export const skillListReducer = slice.reducer;
export default skillList;
