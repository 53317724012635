import React from 'react';
import Spinner from '../spinner/spinner';
import styles from './app-loading.module.scss';

export default function AppLoading() {
  return (
    <div className={styles['app-fallback']}>
      <div className={styles['app-loading']}>
        <Spinner type="rotating-plane" color={'brand-primary'} />
      </div>
    </div>
  );
}
