import { IQuizInfoResponse, IQuizAnswer } from './../../http/api/v1/quiz/quiz';
import { ELoadingStatus, EQuizPage } from '@http/enums';
import { TLoadingStatus } from './../../types/loading-status';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store';
import { IQuizInfo } from '../../http/api/v1/quiz/quiz';
import { v1 } from '@api/v1';
import { IQuizQuestion } from '../../http/models/quiz-question';

export interface IQuiz extends IQuizInfo, IQuizQuestion {
  error?: string;
  loadingStatus?: TLoadingStatus;
  currentPageType?: EQuizPage;
  answers?: IQuizAnswer[];
}

const initialState: { [p: number]: IQuiz } = {};

const slice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {
    setCurrentPageType: (
      state,
      action: PayloadAction<{ id: number; currentPageType: EQuizPage }>,
    ) => {
      if (!state[action.payload.id]) {
        state[action.payload.id] = { id: action.payload.id } as IQuiz;
      }
      state[action.payload.id].currentPageType = action.payload.currentPageType;
    },
    setError: (state, action: PayloadAction<{ id: number; error?: string }>) => {
      if (!state[action.payload.id]) {
        state[action.payload.id] = { id: action.payload.id } as IQuiz;
      }
      state[action.payload.id].error = action.payload.error;
    },
    setLoadingStatus: (
      state,
      action: PayloadAction<{ id: number; loadingStatus: TLoadingStatus }>,
    ) => {
      if (!state[action.payload.id]) {
        state[action.payload.id] = { id: action.payload.id } as IQuiz;
      }
      state[action.payload.id].loadingStatus = action.payload.loadingStatus;
    },
    setQuiz: (state, action: PayloadAction<{ id: number; quiz: IQuizInfoResponse }>) => {
      state[action.payload.id] = { ...state[action.payload.id], ...action.payload.quiz } as IQuiz;
    },
    setAnswer: (state, action: PayloadAction<{ id: number; answer: IQuizAnswer }>) => {
      const { id, answer } = action.payload;
      const quiz = state[id];

      if (quiz) {
        if (!quiz.answers) {
          quiz.answers = [];
        }

        const existingAnswerIndex = quiz.answers.findIndex((a) => a.order === answer.order);

        if (existingAnswerIndex !== -1) {
          quiz.answers[existingAnswerIndex] = answer;
        } else {
          quiz.answers.push(answer);
        }
      }
    },
  },
});

const { setError, setLoadingStatus, setQuiz, setCurrentPageType } = slice.actions;

const startQuizAndGetQuestion =
  (quizId: number): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    dispatch(setLoadingStatus({ id: quizId, loadingStatus: ELoadingStatus.Loading }));

    try {
      const startResponse = await v1.quiz?.start.post(quizId);

      if (startResponse.errorCode) {
        dispatch(setError({ id: quizId, error: startResponse.errorMsg }));
        return;
      }

      dispatch(setQuiz({ id: quizId, quiz: { ...state.quiz[quizId], ...startResponse } }));

      const questionsResponse = await v1.quiz?.questions.get(quizId);

      if (questionsResponse.errorCode) {
        dispatch(setError({ id: quizId, error: questionsResponse.errorMsg }));
        return;
      }

      dispatch(setQuiz({ id: quizId, quiz: { ...state.quiz[quizId], ...questionsResponse } }));
      dispatch(setCurrentPageType({ id: quizId, currentPageType: EQuizPage.QuizQuestion }));
    } catch (error) {
      dispatch(setError({ id: quizId, error: error.message || 'An unexpected error occurred' }));
    } finally {
      dispatch(setLoadingStatus({ id: quizId, loadingStatus: ELoadingStatus.Succeeded }));
    }
  };

const loadQuizInfo =
  (challengeId: number, quizId: number): AppThunk =>
  async (dispatch, getState) => {
    let response: any;
    const state = getState();
    dispatch(setLoadingStatus({ id: quizId, loadingStatus: ELoadingStatus.Loading }));
    dispatch(setCurrentPageType({ id: quizId, currentPageType: EQuizPage.QuizInfo }));
    try {
      response = await v1.quiz?.info.get(challengeId);
      if (response.errorCode) {
        dispatch(setError({ id: response.id, error: response.errorMsg }));
        return;
      }

      dispatch(
        setQuiz({
          id: response.id,
          quiz: { ...state.quiz[response.id], ...response, challengeId: challengeId },
        }),
      );
    } catch (error) {
      dispatch(setError({ id: quizId, error: error.message || 'An unexpected error occurred' }));
    } finally {
      dispatch(setLoadingStatus({ id: response.id, loadingStatus: ELoadingStatus.Succeeded }));
    }
  };

const quiz = {
  ...slice.actions,
  loadQuizInfo,
  startQuizAndGetQuestion,
  selectError: (id: number) => (state: RootState) => state.quiz[id]?.error,
  selectLoadingStatus: (id: number) => (state: RootState) => state.quiz[id]?.loadingStatus,
  selectCurrentPageType: (id: number) => (state: RootState) => state.quiz[id]?.currentPageType,
  selectQuiz: (id: number) => (state: RootState) => state.quiz[id],
};

export const quizReducer = slice.reducer;
export default quiz;
