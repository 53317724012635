import React from 'react';
import { BottomSheet } from '@component/bottom-sheet/bottom-sheet';

type BottomSheetContextType = {
  content: React.ReactNode | null;
  show: (content: React.ReactNode) => void;
  hide: () => void;
  visible: boolean;
};

export const BottomSheetContext = React.createContext<BottomSheetContextType>({
  content: null,
  show: () => {},
  hide: () => {},
  visible: false,
});

export const BottomSheetProvider: React.FC = ({ children }) => {
  const [content, setContent] = React.useState<React.ReactNode | null>(null);
  const [visible, setVisible] = React.useState(false);

  const show = (newContent: React.ReactNode) => {
    setContent(newContent);
    setVisible(true);
  };

  const hide = () => {
    setVisible(false);
  };

  return (
    <BottomSheetContext.Provider value={{ content, show, hide, visible }}>
      {children}
      {visible && <BottomSheet>{content}</BottomSheet>}
    </BottomSheetContext.Provider>
  );
};
