import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Localization } from './localization';
import { useSwipeDownToRefresh } from './hooks/use-swipe-down-to-refresh';

import { ThemeProvider } from './providers/theme-provider';
import { SchemeProvider } from './providers/scheme-provider';
import { BottomSheetProvider } from './providers/bottom-sheet-provider';

//todo: разобраться с импортом провайдеров
const MobileLayout = React.lazy(async () => import('./views/layout/mobile-layout'));
const ScrollToTop = React.lazy(
  async () => import('./views/components/scroll-to-top/scroll-to-top'),
);
const TrackRouteChange = React.lazy(
  async () => import('./views/components/track-route-change/track-route-change'),
);
const AppFeatureConfigLoader = React.lazy(() => import('./app-feature-config-loader'));
const VersionCheckerProvider = React.lazy(
  async () => import('./providers/version-checker-provider'),
);

const App = () => {
  useSwipeDownToRefresh();

  return (
    <BrowserRouter>
      <SchemeProvider>
        <ScrollToTop />
        <TrackRouteChange />
        <ThemeProvider>
          <Localization>
            <AppFeatureConfigLoader>
              <VersionCheckerProvider />
              <BottomSheetProvider>
                <Routes>
                  <Route path="/*" element={<MobileLayout />} />
                  <Route path="/m/*" element={<MobileLayout />} />
                </Routes>
              </BottomSheetProvider>
            </AppFeatureConfigLoader>
          </Localization>
        </ThemeProvider>
      </SchemeProvider>
    </BrowserRouter>
  );
};

export default App;
