import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store';
import { isEmpty } from 'lodash';
import { IRubricItem } from '../../http/models/rubric-item';
import { v1 } from '@api/v1';
import { v0_1 } from '@api/v0_1';

interface RubricsListState {
  error?: string;
  loading: boolean;
  items: IRubricItem[];
  selectedItems: IRubricItem[];
}

const initialState: RubricsListState = {
  loading: true,
  items: [],
  selectedItems: [],
};

const slice = createSlice({
  name: 'rubricsList',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setItems: (state, action: PayloadAction<IRubricItem[]>) => {
      state.items = action.payload;
    },
    setSelectedItems: (state, action: PayloadAction<IRubricItem[]>) => {
      state.selectedItems = action.payload;
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
  },
});

const { setLoading, setItems, setError, setSelectedItems } = slice.actions;

const rubricsList = {
  setLoading,
  setItems,
  setError,
  setSelectedItems,
  selectLoading: (state: RootState) => state.rubricsList.loading,
  selectItems: (state: RootState) => state.rubricsList.items,
  selectSelectedItems: (state: RootState) => state.rubricsList.selectedItems,
  selectError: (state: RootState) => state.rubricsList.error,
  loadData: (): AppThunk => async (dispatch) => {
    try {
      dispatch(setError(''));
      const response = await v1.rubrics.get();
      if (response.errorCode) {
        dispatch(setError(response.errorMsg));
        console.error(`errorCode: ${response.errorCode}; errorMsg: ${response.errorMsg}`);
        return;
      }
      dispatch(setItems(response.items ?? []));
    } finally {
      dispatch(setLoading(false));
    }
  },
  loadDataSelectedRubric: (): AppThunk => async (dispatch) => {
    try {
      dispatch(setError(''));
      const response = await v0_1.rubrics.currentUserRubrics.get();
      isEmpty(response) && dispatch(setSelectedItems(response));
    } finally {
      dispatch(setLoading(false));
    }
  },
  updateNewSelectedRubrics:
    (rubricList: IRubricItem[]): AppThunk =>
    async (dispatch) => {
      try {
        dispatch(setError(''));
        const rubricIds = rubricList.map((item) => item.id);

        const response = await v0_1.rubrics.post(rubricIds);
        dispatch(setSelectedItems(rubricList));

        if (response.errorCode) {
          dispatch(setError(response.errorMsg));
          console.log(`errorCode: ${response.errorCode}; errorMsg: ${response.errorMsg}`);
          return;
        }
      } finally {
        dispatch(setLoading(false));
      }
    },
};

export const rubricsListReducer = slice.reducer;
export default rubricsList;
