import parser from "ua-parser-js";

/**
 * Браузер
 */
interface IBrowser {
  /**
   * Название браузера
   */
  readonly name: string | undefined;
  /**
   * Версия браузера
   */
  readonly version: string | undefined;

  /**
   * Мажорная версия браузера
   */
  readonly major: string | undefined;
}

/**
 * Устройство
 */
interface IDevice {
  /**
   * Модель устройства
   */
  readonly model: string | undefined;
  /**
   * Тип устройства
   */
  readonly type: string | undefined;
  /**
   * Производитель устройства
   */
  readonly manufacturer: string | undefined;
}

/**
 * Движок
 */
interface IEngine {
  /**
   * Наименование
   */
  readonly name: string | undefined;
  /**
   * Версия
   */
  readonly version: string | undefined;
}

/**
 * Операционная система
 */
interface IOS {
  /**
   * Наименование
   */
  readonly name: string | undefined;
  /**
   * Версия
   */
  readonly version: string | undefined;
}

/**
 * Процессор
 */
interface ICPU {
  /**
   * Архитектура
   */
  readonly architecture: string | undefined;
}

interface IUserAgentData {
  readonly ua: string;
  readonly browser: IBrowser;
  readonly device: IDevice;
  readonly engine: IEngine;
  readonly os: IOS;
  readonly cpu: ICPU;
}

const userAgentData: IUserAgentData = (() => {
  const data = parser(window.navigator.userAgent);

  return {
    ua: data.ua,
    browser: data.browser,
    device: {
      model: data.device.model,
      type: data.device.type,
      manufacturer: data.device.vendor
    },
    engine: data.engine,
    os: data.os,
    cpu: data.cpu
  };
})();

export default userAgentData
