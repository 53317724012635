import { isEmpty, unionBy } from 'lodash';
import { IProjectItem } from '@model/project-item';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EProjectSortingOrder } from '@http/enums';
import { AppThunk, RootState } from '../store';
import storage from '..';
import { v0_1 } from '@api/v0_1';
import { ICommentItem } from '@model/comment-item';
import { v1 } from '@api/v1';
import { batch } from 'react-redux';

export interface ILoadProjectRequestProps {
  position?: number;
  take?: number;
  direction?: 'next' | 'prev';
  sortingOrder?: EProjectSortingOrder;
}

type ProjectsState = Record<number, IProjectItem>;

const initialState: ProjectsState = {};

const slice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    addItems: (state, action: PayloadAction<IProjectItem[]>) => {
      action.payload.forEach((project) => {
        state[project.id] = project;
      });
    },
    updateItem: (state, action: PayloadAction<IProjectItem>) => {
      const project = action.payload;
      if (state[project.id]) {
        state[project.id] = { ...state[project.id], ...project };
      } else {
        state[project.id] = project;
      }
    },
    deleteItem: (state, action: PayloadAction<number>) => {
      const projectId = action.payload;
      delete state[projectId];
    },
    toggleLikeOnProject: (state, action: PayloadAction<{ id: number; user: any }>) => {
      const project: any = state[action.payload.id];

      if (project) {
        project.userLiked = !project.userLiked;
        project.likedByCurrentUser = !project.likedByCurrentUser;
        if (project.userLiked) {
          project.likesCount = (project.likesCount || 0) + 1;
          project.recentlyLikedUser = [
            ...(project.recentlyLikedUser || []),
            {
              userAvatar: action.payload.user.avatarUrl,
              userId: action.payload.user.id,
              userName: action.payload.user.userName,
            },
          ];
        } else {
          project.likesCount = (project.likesCount || 0) - 1;
          project.recentlyLikedUser = project.recentlyLikedUser?.filter(
            (user: any) => user.userId !== action.payload.user.id,
          );
        }
      }
    },
    getProjectsByIds: (state, action: PayloadAction<number[]>): IProjectItem[] => {
      return action.payload.map((id) => state[id]).filter(Boolean);
    },
    setComments: (state, action: PayloadAction<{ id: number; comments: ICommentItem[] }>) => {
      const project = state[action.payload.id];
      if (project) {
        project.comments.items = action.payload.comments;
      }
    },
    addComment: (state, action: PayloadAction<{ id: number; comment: ICommentItem }>) => {
      const project = state[action.payload.id];
      if (project) {
        project.comments.items.push(action.payload.comment);
      }
    },
    setCommentsTotal: (state, action: PayloadAction<{ id: number; total: number }>) => {
      const project = state[action.payload.id];
      if (project) {
        project.comments.total = action.payload.total;
      }
    },
    setCommentsPage: (state, action: PayloadAction<{ id: number; page: number }>) => {
      const project = state[action.payload.id];
      if (project) {
        project.comments.page = action.payload.page;
      }
    },
    setCommentsError: (state, action: PayloadAction<{ id: number; error: string }>) => {
      const project = state[action.payload.id];
      if (project) {
        if (!project.comments) {
          project.comments = {
            items: [],
            total: 0,
            page: 1,
            error: '',
          };
        }
        project.comments.error = action.payload.error;
      }
    },
    reset: () => initialState,
  },
});

export const {
  addItems,
  deleteItem,
  toggleLikeOnProject,
  updateItem,
  reset,
  setCommentsError,
  setComments,
  addComment,
  setCommentsTotal,
  setCommentsPage,
} = slice.actions;

const projects = {
  ...slice.actions,
  selectProject: (id: number) => (state: RootState) => state.projects[id],
  selectListProject: () => (state: RootState) => state.projects,
  selectCommentsTotal: (id: number) => (state: RootState) =>
    state?.projects[id]?.comments?.total ?? 1,
  selectCommentsError: (id: number) => (state: RootState) => state?.projects[id]?.comments?.error,
  loadComments:
    (id: number, loadMore?: boolean, take: number = 20): AppThunk =>
    async (dispatch, getState) => {
      const state = getState();
      const page = loadMore ? state.projects[id]?.comments?.page + 1 : 1;

      dispatch(setCommentsError({ id, error: '' }));

      try {
        const response = await v1.project.PROJECT_ID.comment.get({
          projectId: String(id),
          page,
          take,
        });

        if (response.errorCode) {
          dispatch(setCommentsError({ id, error: response.errorMsg || '' }));
          return;
        }

        const updatedComments = loadMore
          ? unionBy(state.projects[id]?.comments.items, response.items || [], 'id')
          : response.items || [];

        batch(() => {
          dispatch(setComments({ id, comments: updatedComments }));
          dispatch(setCommentsTotal({ id, total: response.total || 0 }));
          dispatch(setCommentsPage({ id, page }));
        });
      } catch (e) {
        dispatch(setCommentsError({ id, error: e }));
      }
    },
  sendComment:
    (id: number, text: string, parentId: number): AppThunk =>
    async (dispatch, getState) => {
      const state = getState();
      const profile = state.profile.data;
      const random = Math.floor(Math.random() * 1000);

      try {
        const response = await v1.project.PROJECT_ID.comment.post(String(id), {
          text,
          parentId: parentId,
        });

        if (response.errorCode) {
          dispatch(setCommentsError({ id: id, error: response.errorMsg ?? '' }));
          return;
        }

        const newComment = {
          id: Number(response.id) || random,
          text,
          groupId: Number(response.id) || random,
          rootId: parentId,
          parentId,
          createdDate: new Date().toISOString(),
          state: 'Published',
          stateName: 'New Comment',
          author: {
            id: profile.id || '',
            avatar: profile.avatarUrl || '',
            experience: profile.experience || 0,
            isFollowed: false,
            isGuru: profile.krujok || false,
            level: profile.level || 0,
            name: profile.userName || '',
          },
        };

        dispatch(addComment({ id, comment: newComment }));
      } catch (error) {
        dispatch(setCommentsError({ id: id, error: error ?? '' }));
      } finally {
        dispatch(projects.loadComments(id, false));
      }
    },
  toggleLike:
    (id: number): AppThunk =>
    async (dispatch, getState) => {
      try {
        const state = getState();
        const user = state.profile && !isEmpty(state.profile) && state.profile.data;
        const response = await v0_1.projects.PROJECT_ID.likes.post(id);

        if (response.errorCode) {
          console.log(response.errorMsg);
          return;
        }

        !isEmpty(user) && dispatch(toggleLikeOnProject({ id, user }));
        !state.projects[id]?.userLiked && dispatch(storage.userFavoriteProjects.deleteItem(id));
      } catch (error) {
        console.log(error);
      }
    },
};

export const projectsReducer = slice.reducer;
export default projects;
