import HttpClient, { IHttpRequestConfig, IHttpResponse } from './http-client';
import { ApiResponse } from './types';

/**
 * API-клиент
 */
export default interface ApiClient {
  /**
   * Отправить GET-запрос
   * @param url - URL
   * @param config - параметры
   */
  get: <T = any | ApiResponse>(url: string, config?: IHttpRequestConfig) => Promise<T>;

  /**
   * Отправить POST-запрос
   * @param url - URL
   * @param data - данные
   * @param config - параметры
   */
  post: <T = any>(url: string, data?: any, config?: IHttpRequestConfig) => Promise<T>;

  /**
   * Отправить PUT-запрос
   * @param url - URL
   * @param data - данные
   * @param config - параметры
   */
  put: <T = any>(url: string, data?: any, config?: IHttpRequestConfig) => Promise<T>;

  /**
   * Отправить DELETE-запрос
   * @param url - URL
   * @param config - параметры
   */
  delete: <T = any>(url: string, config?: IHttpRequestConfig) => Promise<T>;

  /**
   * Отправить PATCH-запрос
   * @param url - URL
   * @param data - данные
   * @param config - параметры
   */
  patch: <T = any>(url: string, data?: any, config?: IHttpRequestConfig) => Promise<T>;
}

/**
 * Создать API-клиент
 * @param httpClient - HTTP-клиент
 */
export function createApiClient(httpClient: HttpClient): ApiClient {
  const isValidStatus = (status: number) => status >= 200 && status <= 299;

  return {
    get: async <T>(url: string, config?: IHttpRequestConfig): Promise<T> => {
      try {
        const response = await httpClient.get<T>(url, config);
        return isValidStatus(response.status) ? response.data : getError(response);
      } catch (e) {
        return getError(e);
      }
    },

    post: async <T>(url: string, data?: any, config?: IHttpRequestConfig): Promise<T> => {
      try {
        const response = await httpClient.post<T>(url, data, config);
        return isValidStatus(response.status) ? response.data : getError(response);
      } catch (e) {
        return getError(e);
      }
    },

    put: async <T>(url: string, data?: any, config?: IHttpRequestConfig): Promise<T> => {
      try {
        const response = await httpClient.put<T>(url, data, config);
        return isValidStatus(response.status) ? response.data : getError(response);
      } catch (e) {
        return getError(e);
      }
    },

    delete: async <T>(url: string, config?: IHttpRequestConfig): Promise<T> => {
      try {
        const response = await httpClient.delete<T>(url, config);
        return isValidStatus(response.status) ? response.data : getError(response);
      } catch (e) {
        return getError(e);
      }
    },

    patch: async <T>(url: string, data?: any, config?: IHttpRequestConfig): Promise<T> => {
      try {
        const response = await httpClient.patch<T>(url, data, config);
        return isValidStatus(response.status) ? response.data : getError(response);
      } catch (e) {
        return getError(e);
      }
    },
  };
}

/**
 * Получить ошибку из данных
 * @param data - данные
 */
export const getError = (data: any) => {
  const response = data as IHttpResponse;
  if (
    response.status !== undefined &&
    response.statusText !== undefined &&
    response.config !== undefined
  ) {
    return {
      errorCode: response.data?.errorCode ?? response.status,
      errorMsg: getErrorMsg(response.data),
      errors: getValidationErrors(response.data),
    } as any;
  }

  return {
    errorCode: 500,
    errorMsg: getErrorMsg(data),
  } as any;
};

/**
 * Получить сообщение ошибки из данных или системную ошибку
 * @param data - данные
 * @param defaultErrorMsg - сообщение ошибки по умолчанию
 */
export const getErrorMsg = (
  data: any,
  defaultErrorMsg: string = 'Ошибка сервера. Повторите попытку позже',
) => {
  if (typeof data === 'string') {
    return data ?? defaultErrorMsg;
  }

  if (typeof data.errors === 'object') {
    const errors = [];
    for (const [, value] of Object.entries(data.errors)) {
      const err = (value as string[])?.join('. ');
      if (err) {
        errors.push(err);
      }
    }
    return errors.join('. ') || defaultErrorMsg;
  }

  if (typeof data.error === 'string') {
    return data.error ?? defaultErrorMsg;
  }

  if (typeof data.title === 'string') {
    return data.title ?? defaultErrorMsg;
  }

  if (typeof data.errorMsg === 'string') {
    return data.errorMsg ?? defaultErrorMsg;
  }

  return defaultErrorMsg;
};

/**
 * Получить ошибки валидации
 * @param data - данные
 */
const getValidationErrors = (data: any): { [p: string]: string } => {
  const errors: { [p: string]: string } = {};

  if (data && typeof data.errors === 'object') {
    for (const [key, value] of Object.entries(data.errors)) {
      errors[key] = (value as string[])?.join('. ');
    }
  }

  return errors;
};
