import auth from '../http/auth';
import { IHttpRequestConfig } from '../http/http-client';
import { DEFAULT_LOCALE } from '../constants/constants';

export const applyHttpHeadersConfig = (config?: IHttpRequestConfig) => {
  const cfg: IHttpRequestConfig = { ...config };
  cfg.headers = { ...cfg.headers, Authorization: `Bearer ${auth.access_token}` };

  if (!cfg.headers['x-language']) {
    cfg.headers['x-language'] = localStorage.getItem('locale') || DEFAULT_LOCALE;
  }

  return cfg;
};
