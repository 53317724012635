import { ECompanyNames, ELanguage } from '@http/enums';

export const DEFAULT_SCHEME = ECompanyNames.Round;
export const DEFAULT_LOCALE = ELanguage.RU;
export const RUBRIC_KEY_IN_LS = 'users_that_selected_skills';
export const IS_BACK_FROM_EXTERNAL_ROUTE = 'isBackFromExternalRoute';
export const PROJECT_FORM_DATA = 'projectFormData';

export const DOMAINS_VK = [
  'https://www.vk.com/',
  'http://www.vk.com/',
  'https://vk.com/',
  'http://vk.com/',
  'www.vk.com/',
  'vk.com/',
  'https://www.vkontakte.com/',
  'http://www.vkontakte.com/',
  'www.vkontakte.com/',
  'vkontakte.com/',
  'https://www.vk.ru/',
  'http://www.vk.ru/',
  'https://vk.ru/',
  'http://vk.ru/',
  'www.vk.ru/',
  'vk.ru/',
  'https://www.vkontakte.ru/',
  'http://www.vkontakte.ru/',
  'https://vkontakte.ru/',
  'http://vkontakte.ru/',
  'www.vkontakte.ru/',
  'vkontakte.ru/',
];

export const DOMAINS_OK = [
  'https://ok.ru/profile/',
  'http://ok.ru/profile/',
  'https://www.ok.ru/profile/',
  'http://www.ok.ru/profile/',
  'www.ok.ru/profile/',
  'ok.ru/profile/',
  'https://odnoklassniki.ru/profile/',
  'http://odnoklassniki.ru/profile/',
  'https://www.odnoklassniki.ru/profile/',
  'http://www.odnoklassniki.ru/profile/',
  'www.odnoklassniki.ru/profile/',
  'odnoklassniki.ru/profile/',
];
