import { batch } from 'react-redux';
import { IProjectItem } from './../../http/models/project-item';
import { isEmpty } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import { MediaItemDto } from '@api/v0_1/challenges/challenges-instruction';
import storage from '..';
import { IChallengeItem } from '../../http/models/challenge-item';
import { v1 } from '@api/v1';
import { v0_1 } from '@api/v0_1';
import i18next from 'i18next';

interface IChallengeState {
  error?: string;
  loading?: boolean;
  data?: IChallengeItem;
  instructions: {
    error?: string;
    loading?: boolean;
    items: MediaItemDto[];
    total?: number;
  };
  allProjects: {
    error?: string;
    loading?: boolean;
    ids?: number[];
    total?: number;
  };
  myProjects: {
    error?: string;
    loading?: boolean;
    ids?: number[];
    total?: number;
  };
}

const initialState: { [p: string]: IChallengeState } = {};

const slice = createSlice({
  name: 'challengeDetails',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<{ id: number; loading: boolean }>) => {
      state[action.payload.id] = state[action.payload.id] ?? {};
      state[action.payload.id].loading = action.payload.loading;
    },
    setError: (state, action: PayloadAction<{ id: number; error?: string }>) => {
      state[action.payload.id] = state[action.payload.id] ?? {};
      state[action.payload.id].error = action.payload.error;
    },
    setData: (state, action: PayloadAction<{ id: number; data?: IChallengeItem }>) => {
      state[action.payload.id] = state[action.payload.id] ?? {};
      state[action.payload.id].data = action.payload.data;
    },
    setLiked: (state, action: PayloadAction<{ id: number; liked?: boolean }>) => {
      state[action.payload.id] = state[action.payload.id] ?? {};
      state[action.payload.id].data = state[action.payload.id].data ?? {};
      state[action.payload.id].data!.liked = action.payload.liked;
      // state[action.payload.id].data!.userLiked = action.payload.liked; // todo
      state[action.payload.id].data!.likesCount = action.payload.liked
        ? (state[action.payload.id].data!.likesCount || 0) + 1
        : (state[action.payload.id].data!.likesCount || 0) - 1;
    },
    setInstructionsLoading: (state, action: PayloadAction<{ id: number; loading: boolean }>) => {
      state[action.payload.id] = state[action.payload.id] ?? {};
      state[action.payload.id].instructions = state[action.payload.id].instructions ?? {};
      state[action.payload.id].instructions!.loading =
        state[action.payload.id].instructions!.loading;
    },
    setInstructionsError: (state, action: PayloadAction<{ id: number; error?: string }>) => {
      state[action.payload.id] = state[action.payload.id] ?? {};
      state[action.payload.id].instructions = state[action.payload.id].instructions ?? {};
      state[action.payload.id].instructions!.error = state[action.payload.id].instructions!.error;
    },
    setInstructionsItems: (
      state,
      action: PayloadAction<{ id: number; items?: MediaItemDto[] }>,
    ) => {
      state[action.payload.id] = state[action.payload.id] ?? {};
      state[action.payload.id].instructions = state[action.payload.id].instructions ?? {};
      state[action.payload.id].instructions!.items = action.payload.items || [];
    },
    setInstructionsTotal: (state, action: PayloadAction<{ id: number; total?: number }>) => {
      state[action.payload.id] = state[action.payload.id] ?? {};
      state[action.payload.id].instructions = state[action.payload.id].instructions ?? {};
      state[action.payload.id].instructions!.total = action.payload.total;
    },
    setAllProjectsLoading: (state, action: PayloadAction<{ id: number; loading: boolean }>) => {
      state[action.payload.id] = state[action.payload.id] ?? {};
      state[action.payload.id].allProjects = state[action.payload.id].allProjects ?? {};
      state[action.payload.id].allProjects!.loading = action.payload.loading;
    },
    setAllProjectsError: (state, action: PayloadAction<{ id: number; error?: string }>) => {
      state[action.payload.id] = state[action.payload.id] ?? {};
      state[action.payload.id].allProjects = state[action.payload.id].allProjects ?? {};
      state[action.payload.id].allProjects!.error = action.payload.error;
    },
    setAllProjectsIds: (state, action: PayloadAction<{ id: number; ids?: number[] }>) => {
      state[action.payload.id] = state[action.payload.id] ?? {};
      state[action.payload.id].allProjects = state[action.payload.id].allProjects ?? {};
      state[action.payload.id].allProjects!.ids = [
        ...(state[action.payload.id].allProjects!.ids || []),
        ...(action.payload.ids ?? []),
      ];
    },
    setAllProjectsTotal: (state, action: PayloadAction<{ id: number; total?: number }>) => {
      state[action.payload.id] = state[action.payload.id] ?? {};
      state[action.payload.id].allProjects = state[action.payload.id].allProjects ?? {};
      state[action.payload.id].allProjects!.total = action.payload.total;
    },
    setMyProjectsLoading: (state, action: PayloadAction<{ id: number; loading: boolean }>) => {
      state[action.payload.id] = state[action.payload.id] ?? {};
      state[action.payload.id].myProjects = state[action.payload.id].myProjects ?? {};
      state[action.payload.id].myProjects!.loading = action.payload.loading;
    },
    setMyProjectsError: (state, action: PayloadAction<{ id: number; error?: string }>) => {
      state[action.payload.id] = state[action.payload.id] ?? {};
      state[action.payload.id].myProjects = state[action.payload.id].myProjects ?? {};
      state[action.payload.id].myProjects!.error = action.payload.error;
    },
    setMyProjectsIds: (state, action: PayloadAction<{ id: number; ids?: number[] }>) => {
      state[action.payload.id] = state[action.payload.id] ?? {};
      state[action.payload.id].myProjects = state[action.payload.id].myProjects ?? {};
      state[action.payload.id].myProjects!.ids = action.payload.ids || [];
    },
    setMyProjectsTotal: (state, action: PayloadAction<{ id: number; total?: number }>) => {
      state[action.payload.id] = state[action.payload.id] ?? {};
      state[action.payload.id].myProjects = state[action.payload.id].myProjects ?? {};
      state[action.payload.id].myProjects!.total = action.payload.total;
    },
  },
});

const {
  setLoading,
  setError,
  setData,
  setLiked,
  setInstructionsLoading,
  setInstructionsError,
  setInstructionsItems,
  setInstructionsTotal,
  setAllProjectsLoading,
  setAllProjectsError,
  setAllProjectsIds,
  setAllProjectsTotal,
  setMyProjectsLoading,
  setMyProjectsError,
  setMyProjectsIds,
  setMyProjectsTotal,
} = slice.actions;

const challengeDetails = {
  ...slice.actions,
  selectLoading: (id: number) => (state: RootState) => state.challengeDetails[id]?.loading ?? true,
  selectError: (id: number) => (state: RootState) => state.challengeDetails[id]?.error,
  selectData: (id: number) => (state: RootState) => state.challengeDetails[id]?.data,
  selectLiked: (id: number) => (state: RootState) =>
    state.challengeDetails[id]?.data?.liked ?? false,
  selectActionButton: (id: number) => (state: RootState) =>
    state.challengeDetails[id]?.data?.actionButton ?? {},
  toggleFavourite:
    (id: number): AppThunk =>
    async (dispatch, getState) => {
      const state = getState();
      try {
        const response = await v0_1.challenges.CHALLENGE_ID.likes.post(id);
        if (response.errorCode) {
          console.warn(response.errorMsg);
          return;
        }
      } finally {
        dispatch(setLiked({ id, liked: !state.challengeDetails[id]?.data?.liked }));
      }
    },
  loadData:
    (id: number): AppThunk =>
    async (dispatch) => {
      try {
        dispatch(setError({ id, error: '' }));
        const response = await v1.challenge.CHALLENGE_ID.get(id);
        if (response.errorCode) {
          if (response.errorMsg?.startsWith('<!DOCTYPE html>')) {
            dispatch(
              setError({
                id,
                error: response.errorCode + ' ' + i18next.t('common__msg_error_server'),
              }),
            );
          } else {
            dispatch(setError({ id, error: response.errorMsg }));
          }
          return;
        }
        dispatch(setData({ id, data: response }));
      } finally {
        dispatch(setLoading({ id, loading: false }));
      }
    },
  instructions: {
    setError: (id: number, error?: string) => setInstructionsError({ id, error }),
    setLoading: (id: number, loading: boolean) => setInstructionsLoading({ id, loading }),
    selectError: (id: number) => (state: RootState) =>
      state.challengeDetails[id]?.instructions?.error,
    selectLoading: (id: number) => (state: RootState) =>
      state.challengeDetails[id]?.instructions?.loading ?? true,
    selectItems: (id: number) => (state: RootState) =>
      state.challengeDetails[id]?.instructions?.items ?? [],
    selectTotal: (id: number) => (state: RootState) =>
      state.challengeDetails[id]?.instructions?.total ?? 0,
    loadData:
      (id: number): AppThunk =>
      async (dispatch) => {
        try {
          dispatch(setInstructionsError({ id, error: '' }));
          const response = await v0_1.challenges.CHALLENGE_ID.instruction.get(id);
          if (response.errorCode) {
            dispatch(setInstructionsError({ id, error: response.errorMsg }));
            return;
          }
          dispatch(setInstructionsItems({ id, items: response.length ? response : [] }));
          dispatch(setInstructionsTotal({ id, total: response?.length }));
        } finally {
          dispatch(setInstructionsLoading({ id, loading: false }));
        }
      },
  },
  allProjects: {
    setError: (id: number, error?: string) => setAllProjectsError({ id, error }),
    setLoading: (id: number, loading: boolean) => setAllProjectsLoading({ id, loading }),
    selectError: (id: number) => (state: RootState) =>
      state.challengeDetails[id]?.allProjects?.error,
    selectLoading: (id: number) => (state: RootState) =>
      state.challengeDetails[id]?.allProjects?.loading ?? true,
    selectItems: (id: number) => (state: RootState) =>
      state.challengeDetails[id]?.allProjects?.ids
        ?.map((id: number) => state.projects[id])
        .filter(Boolean) || [],
    selectTotal: (id: number) => (state: RootState) =>
      state.challengeDetails[id]?.allProjects?.total ?? 0,
    loadData:
      ({ id, page }: { id: number; page: number }): AppThunk =>
      async (dispatch) => {
        try {
          dispatch(setAllProjectsError({ id }));
          const response = await v1.project.get({
            challengeId: [id],
            skills: { fetch: true },
            medias: { fetch: true },
            take: 25,
            page,
          });
          batch(() => {
            if (response.errorCode) {
              dispatch(setAllProjectsError({ id, error: response.errorMsg }));
              return;
            }
            if (response && !isEmpty(response.items)) {
              dispatch(storage.projects.addItems(response.items));
              dispatch(
                setAllProjectsIds({
                  id,
                  ids: (response.items as IProjectItem[]).map((item: IProjectItem) => item.id),
                }),
              );
              dispatch(setAllProjectsTotal({ id, total: response.total }));
            }
          });
        } finally {
          dispatch(setAllProjectsLoading({ id, loading: false }));
        }
      },
  },
  myProjects: {
    setError: (id: number, error?: string) => setMyProjectsError({ id, error }),
    setLoading: (id: number, loading: boolean) => setMyProjectsLoading({ id, loading }),
    selectError: (id: number) => (state: RootState) =>
      state.challengeDetails[id]?.myProjects?.error,
    selectLoading: (id: number) => (state: RootState) =>
      state.challengeDetails[id]?.myProjects?.loading ?? true,
    selectItems: (id: number) => (state: RootState) =>
      state.challengeDetails[id]?.myProjects?.ids
        ?.map((id: number) => state.projects[id])
        .filter(Boolean) || [],
    selectTotal: (id: number) => (state: RootState) =>
      state.challengeDetails[id]?.myProjects?.total ?? 0,
    loadData:
      (challengeId: number, userId?: string): AppThunk =>
      async (dispatch) => {
        if (!userId) {
          return;
        }
        try {
          dispatch(setMyProjectsError({ id: challengeId }));
          const response = await v1.project.get({
            challengeId: [challengeId],
            skills: { fetch: true },
            medias: { fetch: true },
            author: { id: [userId] },
          });
          batch(() => {
            if (response.errorCode) {
              dispatch(setMyProjectsError({ id: challengeId, error: response.errorMsg }));
              return;
            }
            if (response && !isEmpty(response.items)) {
              dispatch(storage.projects.addItems(response.items));
              dispatch(
                setMyProjectsIds({
                  id: challengeId,
                  ids: (response.items as IProjectItem[]).map((item: IProjectItem) => item.id),
                }),
              );
              dispatch(setMyProjectsTotal({ id: challengeId, total: response.total }));
            }
          });
        } finally {
          dispatch(setMyProjectsLoading({ id: challengeId, loading: false }));
        }
      },
  },
};

export const challengeDetailsReducer = slice.reducer;
export default challengeDetails;
