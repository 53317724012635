import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store';
import { IUserUpdates } from '../../http/api/v1/user/user-updates';
import { batch } from 'react-redux';
import storage from '..';
import { IProfileUserBlocklist, UpdateProfileRequest } from '../../http/api/v1/profile/profile';
import i18next from 'i18next';
import { IUserItem } from '../../http/models/user-item';
import { v1 } from '@api/v1';

interface IProfileState {
  error?: string;
  loading: boolean;
  logout: boolean;
  updated: boolean;
  data: IUserItem;
}

const initialState: IProfileState = {
  loading: false,
  logout: false,
  updated: false,
  data: {},
};

const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    resetData: (state) => {
      state.data = {};
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setData: (state, action: PayloadAction<IUserItem>) => {
      state.data = { ...state.data, ...action.payload };
    },
    setAvatarUrl: (state, action: PayloadAction<string | undefined>) => {
      state.data.avatarUrl = action.payload;
    },
    setNickname: (state, action: PayloadAction<string | undefined>) => {
      state.data.userName = action.payload;
    },
    setAbout: (state, action: PayloadAction<string | undefined>) => {
      state.data.about = action.payload;
    },
    setEmployeeFirstName: (state, action: PayloadAction<string | undefined>) => {
      state.data.employeeProfile = state.data.employeeProfile ?? {};
      state.data.employeeProfile.firstName = action.payload;
    },
    setEmployeeLastName: (state, action: PayloadAction<string | undefined>) => {
      state.data.employeeProfile = state.data.employeeProfile ?? {};
      state.data.employeeProfile.lastName = action.payload;
    },
    setEmployeeEducationalInstitution: (state, action: PayloadAction<string | undefined>) => {
      state.data.employeeProfile = state.data.employeeProfile ?? {};
      state.data.employeeProfile.educationalInstitution = action.payload;
    },
    setReferenceVkontakte: (state, action: PayloadAction<string | undefined>) => {
      state.data.reference = state.data.reference ?? {};
      state.data.reference.vkontakte = action.payload;
    },
    setReferenceOdnoklassniki: (state, action: PayloadAction<string | undefined>) => {
      state.data.reference = state.data.reference ?? {};
      state.data.reference.odnoklassniki = action.payload;
    },
    setUserUpdates: (state, action: PayloadAction<IUserUpdates>) => {
      state.data.userUpdates = action.payload;
    },
    setProfileUpdated: (state, action: PayloadAction<boolean>) => {
      state.updated = action.payload;
    },
    setBLocklistLoading: (state, action: PayloadAction<boolean>) => {
      state.data.blocklist = state.data.blocklist ?? {};
      state.data.blocklist.loading = action.payload;
    },
    setBlocklistUsers: (state, action: PayloadAction<IProfileUserBlocklist>) => {
      state.data.blocklist = action.payload;
    },
    removeUserFromBlocklist: (state, action: PayloadAction<string>) => {
      state.data.blocklist = state.data.blocklist ?? {};
      state.data.blocklist.items =
        state.data.blocklist?.items!.filter((i: any) => i.nickName !== action.payload) || [];
    },
  },
});

const {
  setProfileUpdated,
  resetData,
  setError,
  setLoading,
  setData,
  setAvatarUrl,
  setNickname,
  setAbout,
  setEmployeeFirstName,
  setEmployeeLastName,
  setEmployeeEducationalInstitution,
  setReferenceVkontakte,
  setReferenceOdnoklassniki,
  setUserUpdates,
  setBlocklistUsers,
  setBLocklistLoading,
  removeUserFromBlocklist,
} = slice.actions;

const profile = {
  setBlocklistUsers,
  setBLocklistLoading,
  removeUserFromBlocklist,
  resetData,
  setProfileUpdated,
  setError,
  setLoading,
  setData,
  setAvatarUrl,
  setNickname,
  setAbout,
  setEmployeeFirstName,
  setEmployeeLastName,
  setReferenceVkontakte,
  setReferenceOdnoklassniki,
  setEmployeeEducationalInstitution,
  selectProfileUpdated: (state: RootState) => state.profile.updated,
  selectError: (state: RootState) => state.profile.error,
  selectLoading: (state: RootState) => state.profile.loading,
  selectData: (state: RootState) => state.profile.data,
  selectAvatarUrl: (state: RootState) => state.profile.data.avatarUrl,
  selectUserName: (state: RootState) => state.profile.data.userName,
  selectBlocklistItems: (state: RootState) => state.profile.data.blocklist?.items,
  loadData: (): AppThunk => async (dispatch) => {
    dispatch(setError());
    dispatch(setLoading(true));
    try {
      const response = await v1.profile.get();

      if (response.errorCode) {
        if (response.errorCode === 500) {
          dispatch(setError(i18next.t('common__msg_error_request')));
        } else {
          dispatch(setError(response.errorMsg));
        }
        return;
      }
      dispatch(setData(response));
    } finally {
      dispatch(setLoading(false));
    }
  },
  loadUserUpdates: (): AppThunk => async (dispatch) => {
    try {
      const response = await v1.user.updates.get();
      if (response.errorCode) {
        console.error(response.errorMsg);
        return;
      }
      dispatch(setUserUpdates(response));
    } catch (e) {
      console.error(e);
    }
  },
  updateUserProfile:
    (data: UpdateProfileRequest): AppThunk =>
    async (dispatch) => {
      try {
        dispatch(setLoading(true));

        const response = await v1.profile.put(data);

        if (response.errorCode) {
          dispatch(
            setError(
              response.errorCode === 2090
                ? i18next.t('common__msg_error_nickname_already_exists')
                : response.errorMsg,
            ),
          );

          return;
        }

        batch(() => {
          dispatch(storage.identity.setNickname(response.userName));
          dispatch(setNickname(response.userName));
          dispatch(setProfileUpdated(true));
          dispatch(setLoading(false));
        });
      } catch (e) {
        console.error(e);
      }
    },
  loadBlocklist: (): AppThunk => async (dispatch) => {
    dispatch(setBLocklistLoading(true));
    try {
      const response = await v1.profile.blocklist.get();

      if (response.errorCode) {
        dispatch(setError(response.errorMsg));
        return;
      }
      dispatch(setBlocklistUsers(response));
    } finally {
      dispatch(setBLocklistLoading(false));
    }
  },
};

export const profileReducer = slice.reducer;
export default profile;
