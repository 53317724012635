import appConfig from '../../app-config';
import { createHttpClient } from '../http-client';
import { createApiClient } from '../api-client';

export const client = {
  default: createHttpClient(appConfig.apiUrl),
  apiService: createHttpClient(appConfig.apiServiceUrl),
};

export const apiClients = {
  default: createApiClient(client.default),
  apiService: createApiClient(client.apiService),
};
