import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface SerializedMedia {
  name: string;
  url: string;
}

interface ICreateProjectState {
  projectName: string;
  projectDescription: string;
  mediaList: File[];
  serializedMediaList: SerializedMedia[];
}

const initialState: ICreateProjectState = {
  projectName: '',
  projectDescription: '',
  mediaList: [],
  serializedMediaList: [],
};

const slice = createSlice({
  name: 'createProject',
  initialState,
  reducers: {
    setProjectName: (state, action: PayloadAction<string>) => {
      state.projectName = action.payload;
    },
    setProjectDescription: (state, action: PayloadAction<string>) => {
      state.projectDescription = action.payload;
    },
    addMedia: (state, action: PayloadAction<File>) => {
      debugger;
      state.mediaList.push(action.payload);
      const url = URL.createObjectURL(action.payload);
      state.serializedMediaList.push({ name: action.payload.name, url });
    },
    changeMedia: (state, action: PayloadAction<{ file: File; preview: any }>) => {
      debugger;
      // state.mediaList = [...(state.mediaList || []), action.payload];
    },
    removeMedia: (state, action: PayloadAction<string>) => {
      debugger;
      state.mediaList = state.mediaList?.filter((item: any) => item.name !== action.payload);
    },
    reset: (state) => {
      state.projectName = '';
      state.projectDescription = '';
      state.mediaList = [];
    },
  },
});

const createProject = {
  ...slice.actions,
  selectData: (state: RootState) => state.createProject,
};

export const createProjectReducer = slice.reducer;
export default createProject;
