import userAgentData from "./utils/user-agent-data";
import { getDeviceId } from "./utils/device";

/**
 * Операционная система
 */
interface IOS {
  /**
   * Наименование
   */
  readonly name?: string;
  /**
   * Версия
   */
  readonly version?: string;
}

/**
 * Клиент
 */
interface IClient {
  /**
   * Идентификатор
   */
  readonly id?: string;
  /**
   * Наименование
   */
  readonly name?: string;
  /**
   * Версия
   */
  readonly version?: string;
}

/**
 * Устройство
 */
interface IDevice {
  /**
   * Идентификатор
   */
  readonly id?: string;
  /**
   * Тип
   */
  readonly type?: string;
  /**
   * Модель
   */
  readonly model?: string;
  /**
   * Производитель
   */
  readonly manufacturer?: string;
}

/**
 * Процессор
 */
interface ICPU {
  /**
   * Архитектура
   */
  readonly architecture: string | undefined;
}

/**
 * Контекст приложения
 */
interface IAppContext {
  /**
   * Операционная система
   */
  readonly os: IOS;
  /**
   * Клиент
   */
  readonly client: IClient;
  /**
   * Устройство
   */
  readonly device: IDevice;
  /**
   * Процессор
   */
  readonly cpu: ICPU
}

/**
 * Контекст приложения
 */
const appContext: IAppContext = {
  os: {
    ...userAgentData.os
  },
  client: {
    id: 'round-web-app',
    ...userAgentData.browser
  },
  device: {
    id: getDeviceId(),
    ...userAgentData.device
  },
  cpu: {
    ...userAgentData.cpu
  }
}

export default appContext;
