import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store';
import { INotificationItem } from '../../http/models/notification-item';
import { v1 } from '@api/v1';

interface INotificationListState {
  loading: boolean;
  items: INotificationItem[];
  total: number;
  error?: string;
}

const initialState: INotificationListState = {
  loading: true,
  items: [],
  total: 0,
};

const slice = createSlice({
  name: 'notificationList',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setItems: (state, action: PayloadAction<INotificationItem[]>) => {
      state.items = [...state.items, ...action.payload];
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
  },
});

const { setLoading, setItems, setTotal, setError } = slice.actions;

const notificationList = {
  setTotal,
  setLoading,
  setItems,
  setError,
  selectLoading: (state: RootState) => state.notificationList.loading,
  selectItems: (state: RootState) => state.notificationList.items,
  selectError: (state: RootState) => state.notificationList.error,
  selectTotal: (state: RootState) => state.notificationList.total,
  loadData:
    (page: number): AppThunk =>
    async (dispatch) => {
      try {
        dispatch(setError());
        const response = await v1.notification.get({
          page: page,
          take: 25,
        });
        if (response.errorCode) {
          dispatch(setError(response.errorMsg));
          return;
        }
        dispatch(setItems(response.items ?? []));
        dispatch(setTotal(response.total ?? 0));
      } finally {
        dispatch(setLoading(false));
      }
    },
};

export const notificationListReducer = slice.reducer;
export default notificationList;
