import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import storage from './storage';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import localTranslationsRU from './i18n/ru.json';
import localTranslationsENG from './i18n/en.json';
import { DEFAULT_LOCALE } from './constants/constants';
import { ELanguage, ELoadingStatus } from '@http/enums';
import { isEmpty } from 'lodash';
import { v1 } from '@api/v1';

// every 6 hours
const UPDATE_INTERVAL = 6 * 60 * 60 * 1000;

export function Localization(props: { children?: React.ReactNode }) {
  const [mergedTranslations, setMergedTranslations] = useState<any>(null);

  const dispatch = useDispatch();
  const locale = useSelector(storage.localization.selectLocal);

  const localeHashToSource = {
    [ELanguage.RU]: localTranslationsRU,
    [ELanguage.EN]: localTranslationsENG,
  };

  // Функция для загрузки и обновления серверных переводов
  const updateServerTranslations = async () => {
    const localTranslations = localeHashToSource[locale];

    i18n.addResourceBundle(locale, 'translation', localTranslations, true, true);

    dispatch(storage.localization.setLoadingStatus(ELoadingStatus.Loading));

    try {
      const response = await v1.glossary.terms.get();

      if (response.errorMsg) {
        dispatch(storage.localization.setLoadingStatus(ELoadingStatus.Failed));
        console.error('Error fetching server translations:', response.errorMsg);

        i18n.addResourceBundle(locale, 'translation', localTranslations, true, true);

        return;
      }

      const serverTranslations: any[] = response.items;

      // Слияние серверных переводов с локальными
      const mergedTranslationsInner = {
        ...localTranslations,
        ...serverTranslations.reduce(
          (result: any, item: any) => ({
            ...result,
            [item.key]: item.value.replace(/\\n/g, '\n').replace(/\\r/g, '\r'),
          }),
          {},
        ),
      };

      setMergedTranslations(mergedTranslationsInner);

      // Обновление ресурсов i18next
      i18n.addResourceBundle(locale, 'translation', mergedTranslationsInner, true, true);

      dispatch(storage.localization.setLoadingStatus(ELoadingStatus.Succeeded));
    } catch (error) {
      dispatch(storage.localization.setLoadingStatus(ELoadingStatus.Failed));
      console.error('Error fetching server translations:', error);
      i18n.addResourceBundle(
        locale,
        'translation',
        isEmpty(mergedTranslations) ? localTranslations : mergedTranslations,
        true,
        true,
      );
    } finally {
      dispatch(storage.localization.setLoadingStatus(ELoadingStatus.Succeeded));
    }
  };

  useEffect(() => {
    i18n.use(initReactI18next).init({
      resources: {
        ru: localTranslationsRU,
        en: localTranslationsENG,
      },
      lng: DEFAULT_LOCALE,
      fallbackLng: DEFAULT_LOCALE,
      interpolation: {
        escapeValue: false,
      },
    });
    // todo: Добавить обработчик изменения языка, если это необходимо
  }, []);

  useEffect(() => {
    // Загрузка серверных переводов для языка по умолчанию при инициализации
    updateServerTranslations();

    // Запуск загрузки серверных переводов каждые 6 часов
    const timer = setInterval(() => {
      updateServerTranslations();
    }, UPDATE_INTERVAL);

    return () => clearInterval(timer);
  }, []);

  return <React.Fragment>{props.children}</React.Fragment>;
}
